import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import loadingGif from '../images/Eclipse.gif';
import { postAjax } from './helpers';
import { KYC } from './kyc/kyc';

/**
 * ApplicationKYC component is responsible for rendering the KYC (Know Your Customer) form.
 * It fetches the KYC data, updates it, and displays the questions to the user.
 *
 * @component
 * @example
 * return (
 *   <ApplicationKYC />
 * )
 *
 * @returns {JSX.Element} The rendered component.
 */
const ApplicationKYC = () => {
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    /**
     * Sends a request to sign a new card and handles the response.
     *
     * This function sets the loading state to true, sends an AJAX POST request with the action 'new_card_sign',
     * and processes the response. If the response is successful, it redirects the user to the sign document URL.
     * If the response is not successful, it throws an error and logs it to the console.
     *
     * @function
     * @returns {void}
     */
    const sendNewCardSign = () => {
        setLoading(true);
        postAjax({ action: 'new_card_sign' })
            .then((response) => response.json())
            .then((jsonData) => {
                if (jsonData.success) {
                    setIsError(false);
                    setErrorMessage('');
                    window.location.assign(jsonData?.data?.sign_document_url);
                } else {
                    throw new Error(jsonData?.data?.message ?? 'Network response was not ok');
                }
            })
            .catch((error) => {
                setLoading(false);
                setIsError(true);
                setErrorMessage(error.message);
            });
    };

    if (loading) {
        return (
            <div className="application-loader"><div className="application-loader-content">
                <img src={loadingGif} className="eclipse" width="120" height="120" />
            </div></div>
        );
    }

    return (
        <>
            <KYC onSuccess={sendNewCardSign} action="new_card_kyc" />
            { isError && <div className="cgi-status">{errorMessage}</div>}
        </>
    );
};

/**
 * StepIndicator Component
 *
 * This component renders a step indicator for a multi-step process. It displays the current step,
 * total steps, and a progress circle to visually represent the progress. Additionally, it shows
 * the title of the current step and the title of the next step.
 *
 * @component
 * @returns {JSX.Element} The rendered step indicator component.
 *
 * @example
 * <StepIndicator />
 *
 * @description
 * - The progress circle is calculated based on the current step and total steps.
 * - The `stepString` is used for accessibility to describe the current step out of the total steps.
 * - The `currentStepTitle` and `nextStepTitle` provide context for the current and next steps in the process.
 */
const StepIndicator = () => {
    const currentStep = 5;
    const totalSteps = 7;
    const stepString = 'Steg ' + currentStep + ' av ' + totalSteps;
    const progress = (currentStep / totalSteps) * 113;
    const currentStepTitle = 'Kundkännedom';
    const nextStepTitle = 'Godkänn och signera';

    return (
        <div className="step-counter">
            <div className="step-counter__indicator">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <title>{stepString}</title>
                    <text x="20" y="21" dominantBaseline="middle" fontSize="14" fill="#666" fontWeight="600" textAnchor="middle" className="step-counter__progress-text">{currentStep}/{totalSteps}</text>
                    <circle cx="20" cy="20" r="18" stroke="#DEDEDE" strokeWidth="3"></circle>
                    <circle cx="20" cy="20" r="18" transform="rotate(-90 20 20)" stroke="#666" strokeWidth="3" strokeDasharray={`${progress} 150`} className="step-counter__progress"></circle>
                </svg>
            </div>
            <div className="step-counter__content">
                <h1 title="<?php echo $stepString ?>">{currentStepTitle}</h1>
                <p aria-hidden="true">Nästa: {nextStepTitle}</p>
            </div>
        </div>
    )
};

document.addEventListener('DOMContentLoaded', () => {
    const kycDiv = document.getElementById('application-kyc');

    if (kycDiv) {
        const root = createRoot(kycDiv);
        root.render(
            <div className="application-form-wrapper application-loader-wrapper">
                <div className="application-form">
                    <StepIndicator />
                    <ApplicationKYC />
                </div>
            </div>
        );
    }
});
